.my-10{
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.card-height{
  height:400px;
 
}

.disabled-form{
 cursor: not-allowed;
}

.disabled-fields{
  pointer-events: none;
}

@media(max-width:767px){
  .card-height{
    height:auto;
  }
}