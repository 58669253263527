.my-10{
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.map-body{
  width:600px;
  height:450px;
}

@media (max-width:767px){
  .map-body{
    width:100%;
    height:100%;
    min-height: 300px;
  }
}