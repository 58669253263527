.my-8-custom{
  margin-top:6rem;
  margin-bottom: 6rem;

  @media (max-width: 767px) {
    margin-bottom: 2rem;
  }
}


/* img{
  object-fit: contain;
} */

.carousel-item{
  max-height: 700px;

  @media (max-width: 767px) {
    min-height:300px;
  }
}

.img-banner{
  object-fit: cover;
  max-height: 700px;
  @media (max-width: 767px) {
    min-height:300px;
    height: 300px;
  }
}