.border-text {
  display: flex;
  flex-direction: row;
}
.border-text:before, .border-text:after{
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}
.border-text:before {
  margin-right: 10px
}
.border-text:after {
  margin-left: 10px
}